import React from 'react'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { font, color, mediaQuery, smartPhone } from '../constants/styles'
import { FormBtn } from './FormBtn'
import  stethoscope  from '../images/Stethoscope.png'

const wrapper = css`
  background-color: ${color.whiteGray};
  padding: 40px 40px;
  margin: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    padding: 20px;
    margin-bottom: 20px;
  }
`
const titlebox = css`
  width: 100%;
  ${mediaQuery[smartPhone]} {
    margin-top: 10px;
  } ;
`

const mainContentsCardLogo = css`
  width: 40px;
  height: 40px;
  background-size: contain;
  float: left;
  margin: 20px;
  background-image: url(${stethoscope});
  ${mediaQuery[smartPhone]} {
    width: 30px;
    height: 30px;
    margin: 20px;
  } ;
`

const mainText1 = css`
  text-align: left;
  white-space: pre-wrap;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.textsize2};
  color: ${color.black};
  line-height: 36px;
  ${mediaQuery[smartPhone]} {
    font-size: 16px;
    line-height: 150%;
  }
`

const mainText2 = css`
  text-align: left;
  white-space: pre-wrap;
  font-family: 'Noto Serif JP', serif;
  font-size: ${font.textsize2};
  color: ${color.black};
  line-height: 36px;
  ${mediaQuery[smartPhone]} {
    font-size: 16px;
    line-height: 150%;
  }
`

const subText = css`
  text-align: left;
  margin: 20px 0;
  font-size: ${font.textSize};
  color: ${color.black};
  line-height: 160%;
  ${mediaQuery[smartPhone]} {
    font-size: ${font.ButtonTextSize};
    padding-bottom: 16px;
  }
`

export const AddContentsCard = (props) => {
  console.log({ stethoscope })
  return (
    <div css={wrapper}>
      <div css={titlebox}>
        <div css={mainContentsCardLogo}></div>
        <p css={mainText1}>{props.mainText1}</p>
        <p css={mainText2}>{props.mainText2}</p>
      </div>
      <p css={subText}>{props.subText}</p>
      <Link to={props.link}>
        <FormBtn title={props.btnText} />
      </Link>
    </div>
  )
}
