import React from "react";
import { css } from "@emotion/core";
import { font, color, mediaQuery, smartPhone } from "../constants/styles";

const formArea = css`
  width: 218px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${color.white};
  font-weight: normal;
  color: ${color.mainD};
  line-height: 2;
  border: 1px solid ${color.mainD};
  border-sizing: border-box;
  border-radius: 4px;
  transition: 0.25s;
  :hover {
    background-color: ${color.mainD};
    border: 1px solid ${color.white};
    color: ${color.white};
  }
  ${mediaQuery[smartPhone]} {
    width: 166px;
    height: 38px;
  }
`;

const tri = css`
  display: block;
  font-size: ${font.ButtonTextSize};
  line-height: 14px;
  padding-right: 8px;
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
    line-height: 12px;
  }
`

const formTitle = css`
  font-size: ${font.ButtonTextSize};
  line-height: 14px;
  ${mediaQuery[smartPhone]} {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const FormBtn = (props) => {
  return (
    <div css={formArea}>
      <p css={tri}>▶</p>
      <p css={formTitle}>{props.title}</p>
    </div>
  );
};
